import ar from './ar/';
import en_US from './en-US/';
import es from './es/';
import fr from './fr/';
import ja_JP from './ja-JP/';
import nl from './nl/';
import pt_BR from './pt-BR/';
import vi from './vi/';
import zh from './zh/';
import test_lng from './test-LNG/';

export default {
  /*...ar,*/
  ...es,
  ...en_US,

  /* ...fr,
   ...ja_JP,
   ...nl,
   ...pt_BR,
   ...vi,
   ...zh,
   ...test_lng*/
};
